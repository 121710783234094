import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
`;

const AppCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: black;

  & img {
    margin: 2rem 0;
    width: 60%;
    filter: grayscale();
    opacity: 0.5;
  }

  & span {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
  }
`;

const AppCard: React.FC<{ name: string; url: string; logoUrl: string }> = ({
  name,
  url,
  logoUrl,
}) => {
  return (
    <AppCardWrapper href={url} target="_blank" rel="noreferrer">
      <img src={logoUrl} alt={`${name} logo`} />
      <span>{name}</span>
    </AppCardWrapper>
  );
};

const Apps: React.FC = () => {
  return (
    <Container>
      <AppCard
        name="Arithmico"
        url="https://arithmico.com"
        logoUrl={"./images/math-explorer-logo.png"}
      />

      <AppCard
        name="Arithmico Docs"
        url="https://docs.arithmico.com"
        logoUrl={"./images/math-explorer-logo.png"}
      />
    </Container>
  );
};

export default Apps;

import React from "react";
import MarkdownCard from "../../common/MarkdownCard";
import impressum from "./impressum";

const About: React.FC = () => {
    return (
        <MarkdownCard content={impressum}/>
    )
};

export default About;
import React from "react";
import Card from "./Card";
import Markdown from "./Markdown";

const MarkdownCard: React.FC<{ content: string }> = ({content}) => {
    return (
        <Card>
            <Markdown content={content}/>
        </Card>
    );
};

export default MarkdownCard;
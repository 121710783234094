import React from "react";
import "./index.css";
import styled from "styled-components";
import Header from "../header/Header";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../views/home/Home";
import Apps from "../views/apps/Apps";
import About from "../views/about/About";

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;

  & > main {
    padding: 40px 15%;
    overflow-y: auto;
  }
`;

const App: React.FC = () => {
  return (
    <Wrapper>
      <Router>
        <Header />
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/apps" component={Apps} />
            <Route exact path="/about" component={About} />
          </Switch>
        </main>
      </Router>
    </Wrapper>
  );
};

export default App;

import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const StyledMarkdown = styled(ReactMarkdown)`
  font-size: 16pt;
  
  & > h1, h2, h3, h4, h5 {
    margin: 10px 0 0 0;
    padding: 0;
    font-weight: 400;
  }
  
  & h5 { font-size: 1.2em }
  & h4 { font-size: 1.4em }
  & h3 { font-size: 1.6em }
  & h2 { font-size: 1.8em }
  & h1 { font-size: 2.0em }
  
  & p { margin: 10px 0 0 0; }
  & strong { font-weight: 400; }
  & a { color: inherit; }
`;

const Markdown: React.FC<{content: string}> = ({content}) => {
    return (<StyledMarkdown>{content}</StyledMarkdown>)
}

export default Markdown;
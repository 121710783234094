import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.header`
  padding: 0 15%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: flex-end;

  & > h1 {
    margin: 0 auto 0 0;
    padding: 10px 0;
    font-size: 1.5rem;
    font-variant: small-caps;
    display: flex;
    align-items: center;
  }

  & a {
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 300;
    font-variant: small-caps;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
  }

  & a:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }

  & nav {
    display: inherit;
    flex-direction: inherit;
  }
`;

const Header: React.FC = () => {
  return (
    <Wrapper>
      <h1>behrenle.com</h1>
      <nav>
        <Link to="/">home</Link>
        <Link to="/apps">apps</Link>
        <Link to="/apis">apis</Link>
        <Link to="/about">über</Link>
      </nav>
    </Wrapper>
  );
};

export default Header;
